'use strict';

var forEach = require('lodash/forEach');
var filter = require('lodash/filter');
var map = require('lodash/map');
var isEmpty = require('lodash/isEmpty');
var indexOf = require('lodash/indexOf');

function filterState(state, filters) {
  var partialState = {};
  var attributeFilters = filter(filters, function(f) { return f.indexOf('attribute:') !== -1; });
  var attributes = map(attributeFilters, function(aF) { return aF.split(':')[1]; });

  if (indexOf(attributes, '*') === -1) {
    forEach(attributes, function(attr) {
      if (state.isConjunctiveFacet(attr) && state.isFacetRefined(attr)) {
        if (!partialState.facetsRefinements) partialState.facetsRefinements = {};
        partialState.facetsRefinements[attr] = state.facetsRefinements[attr];
      }

      if (state.isDisjunctiveFacet(attr) && state.isDisjunctiveFacetRefined(attr)) {
        if (!partialState.disjunctiveFacetsRefinements) partialState.disjunctiveFacetsRefinements = {};
        partialState.disjunctiveFacetsRefinements[attr] = state.disjunctiveFacetsRefinements[attr];
      }

      if (state.isHierarchicalFacet(attr) && state.isHierarchicalFacetRefined(attr)) {
        if (!partialState.hierarchicalFacetsRefinements) partialState.hierarchicalFacetsRefinements = {};
        partialState.hierarchicalFacetsRefinements[attr] = state.hierarchicalFacetsRefinements[attr];
      }

      var numericRefinements = state.getNumericRefinements(attr);
      if (!isEmpty(numericRefinements)) {
        if (!partialState.numericRefinements) partialState.numericRefinements = {};
        partialState.numericRefinements[attr] = state.numericRefinements[attr];
      }
    });
  } else {
    if (!isEmpty(state.numericRefinements)) {
      partialState.numericRefinements = state.numericRefinements;
    }
    if (!isEmpty(state.facetsRefinements)) partialState.facetsRefinements = state.facetsRefinements;
    if (!isEmpty(state.disjunctiveFacetsRefinements)) {
      partialState.disjunctiveFacetsRefinements = state.disjunctiveFacetsRefinements;
    }
    if (!isEmpty(state.hierarchicalFacetsRefinements)) {
      partialState.hierarchicalFacetsRefinements = state.hierarchicalFacetsRefinements;
    }
  }

  var searchParameters = filter(
    filters,
    function(f) {
      return f.indexOf('attribute:') === -1;
    }
  );

  forEach(
    searchParameters,
    function(parameterKey) {
      partialState[parameterKey] = state[parameterKey];
    }
  );

  return partialState;
}

module.exports = filterState;
