'use strict';

var reduce = require('lodash/reduce');
var find = require('lodash/find');
var startsWith = require('lodash/startsWith');

/**
 * Transform sort format from user friendly notation to lodash format
 * @param {string[]} sortBy array of predicate of the form "attribute:order"
 * @return {array.<string[]>} array containing 2 elements : attributes, orders
 */
module.exports = function formatSort(sortBy, defaults) {
  return reduce(sortBy, function preparePredicate(out, sortInstruction) {
    var sortInstructions = sortInstruction.split(':');
    if (defaults && sortInstructions.length === 1) {
      var similarDefault = find(defaults, function(predicate) {
        return startsWith(predicate, sortInstruction[0]);
      });
      if (similarDefault) {
        sortInstructions = similarDefault.split(':');
      }
    }
    out[0].push(sortInstructions[0]);
    out[1].push(sortInstructions[1]);
    return out;
  }, [[], []]);
};
